import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { IOpenGraphData, ISeoData } from '../../model/Common/common.interface'
import { RINGLE_DOMAIN } from '../../modules/envVars'
import { LOCALE_KO } from '../../modules/i18n/config'
import { s_head } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { CDN_URL, FACEBOOK_APP_ID } from '../../modules/vars'

// meta 태그는 Head 엘리먼트의 바로 자식 노드이거나. 1 레벨 깊이의 <Fragment>로 감싸져 잇어야 한다.
// 따라서 컴포넌트로 넣기보다 함수를 호출함

interface IBasicHead {
  seoData?: ISeoData
  ogData?: IOpenGraphData
}

const BasicHead = ({ seoData, ogData }: IBasicHead) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [lang, setLang] = useState(router.query?.lang ?? LOCALE_KO)
  const [originPath, setOriginPath] = useState(router.pathname.replace('/[lang]/', '/'))

  useEffect(() => {
    setOriginPath(router.pathname.replace('/[lang]/', '/'))

    if (router.query.lang && !Array.isArray(router.query.lang)) {
      setLang(router.query.lang)
    }
  }, [router.pathname, router.query])

  const getValue = useCallback(
    (value: string | undefined, ogValue: string | undefined, fallbackValue: string) =>
      value ? value : ogValue ? ogValue : fallbackValue,
    []
  )

  const title =
    lang == LOCALE_KO ? '링글 틴즈 10대를 위한 1:1 화상영어' : 'Ringle Teens: 1:1 tutoring platform for true learning'

  const doc = {
    name: 'RINGLE TEENS',
    title: getValue(seoData?.pageTitle, ogData?.title, title),
    desc: getValue(
      seoData?.pageDescription,
      ogData?.desc,
      'Have an in-depth discussion with tutors from the world’s best universities'
    ),
    keywords: getValue(seoData?.pageKeywords, ogData?.keywords, t(s_head.student.keywords)),
    author: ogData?.author ? ogData.author : t(s_head.student.author),
    og: {
      url: ogData?.ogUrl ? ogData.ogUrl : 'https://www.ringleteens.com/' + lang + originPath,
      type: 'website',
      siteName: ogData?.siteName ? ogData.siteName : 'Ringle Teens',
      title: ogData?.title ? ogData.title : title,
      description: ogData?.desc
        ? ogData.desc
        : 'Have an in-depth discussion with tutors from the world’s best universities',
      image: ogData?.image ? ogData.image : `${CDN_URL}/new-theme/teens/ringle_teens_SEO_image.png`,
      imageSecureUrl: ogData?.image ? ogData.image : `${CDN_URL}/web/og_thumbnail.png`,
    },
    noscript: t(s_head.student.noscript),
  }

  const vars = {
    googleVerification: 'oP29BXHffBGVD43S2R0cx9qRuJMcstXpbRovsraTD3U',
    naverVerification: '9ca9ea00c2d2f9a03df4531d309964c1a72ad32e',
    facebookVerification: 'mpikl7qm4qcqhuy62swrhkrt419wgs',
  }

  return (
    <Head>
      <title>{doc.title}</title>
      <meta charSet="utf-8" />
      <meta name="naver-site-verification" content={vars.naverVerification} />
      <meta name="facebook-domain-verification" content={vars.facebookVerification} />
      <meta name="google-site-verification" content={vars.googleVerification} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
      />

      <meta name="name" content={doc.name} />
      <meta name="description" content={doc.desc} />
      <meta name="keywords" content={doc.keywords} />
      <meta property="og:url" content={`${doc.og.url}/`} />
      <meta property="og:type" content={doc.og.type} />
      <meta property="og:site_name" content={doc.og.siteName} />
      <meta property="og:title" content={doc.og.title} />
      <meta property="og:description" content={doc.og.description} />
      <meta property="og:image" content={doc.og.image} />
      <meta property="og:image:secure_url" content={doc.og.imageSecureUrl} />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />

      {seoData?.pageCanonicalPath ? (
        <link rel="canonical" href={seoData?.pageCanonicalPath} />
      ) : (
        <link rel="canonical" href={'https://' + RINGLE_DOMAIN + '/' + lang + originPath} />
      )}

      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/ko' + originPath} hrefLang="ko" />
      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/en' + originPath} hrefLang="en" />
      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/ja' + originPath} hrefLang="ja" />
      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/vi' + originPath} hrefLang="vi" />
      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/zh_cht' + originPath} hrefLang="zh-TW" />
      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/zh_cht' + originPath} hrefLang="zh-HK" />
      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/zh_chs' + originPath} hrefLang="zh-CN" />

      {/** noscript **/}
      <noscript>{doc.noscript}</noscript>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Organization',
            name: doc.name,
            description: doc.desc,
            url: 'https://' + RINGLE_DOMAIN + '/' + lang + originPath,
            sameAs: [
              'https://www.youtube.com/channel/UCG2DUiUkDuQckaRjkxqFUog/ringleplus.com',
              'https://www.facebook.com/ringleedu',
              'https://www.instagram.com/ringleedu',
              'https://blog.naver.com/ringleedu',
              'https://pf.kakao.com/_sYFgV',
              'https://play.google.com/store/apps/details?id=com.ringle',
              'https://apps.apple.com/app/ringle-1-1-online-english/id1189876556',
            ],
          }),
        }}
      />
    </Head>
  )
}

export default React.memo(BasicHead as React.FunctionComponent<IBasicHead>)
